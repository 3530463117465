<template>
	<Layout>
		<v-container>
			<div v-if="nextEventos.length">
				<v-row justify="center" v-for="(evento,index) in nextEventos" :key="evento._id">
					<v-col cols="12" class="mb-5">
						<base-total-icon
							:registro="inscripcionesNexEvento[index]"
							title="RESUMEN INSCRIPCIONES"
							icon="mdi-calendar"
							color="secondary"
							:subtitle="evento.titulo"
							:key="inscripcionKey"
						/>
						<div class="text-right">
							<!-- <v-btn
								class="ma-2"
								outlined
								color="primary"
								:to="{name: 'InscripcionesNextEvento', params: { id: evento._id }}"
							>
								Ir al listado
							</v-btn> -->
						</div>
					</v-col>
				</v-row>
			</div>




			<v-row justify="center">
				<v-col cols="12" v-if="loadingUsuarios">
					<progress-component />
				</v-col>
				<v-col cols="12" class="mb-5" v-else>
					<base-total-icon-general
						:registros="users"
						title="REGISTRO GENERAL EN LA PLATAFORMA"
						icon="mdi-account-group"
						color="primary"
						subtitle="RESUMEN DE REGISTROS"
						:key="userKey"
					/>
					<div class="text-right">
						<v-btn
							class="ma-2"
							outlined
							color="primary"
							:to="{name: 'UsersList'}"
						>
							Ir al listado
						</v-btn>
					</div>
				</v-col>
			</v-row>
		</v-container>
	</Layout>
</template>

<script>
	import axios from "axios";
	import store from '@/store';
	import BaseTotalIcon from '@/components/base/TotalIcon.vue'
	import BaseTotalIconGeneral from '@/components/base/TotalIconGeneral.vue'
	import ProgressComponent from '@/components/base/Progress.vue'
	const header = {"Token": store.state.userModule.token}
	const configuration = {headers: header}
	export default {
		name: 'Dashboard',
		components: {
			BaseTotalIcon,
			BaseTotalIconGeneral,
			ProgressComponent
		},
		data: () => ({
			evento: {
				_id: '',
				titulo: '',
				subtitulo: '',
				slug: ''
			},
			nextEventos: [],
			users: [],
			nextEvento: {
				_id: '',
				titulo: '',
				subtitulo: '',
				slug: ''
			},
			loadingNext: false,
			loadingInscripciones: false,
			loadingUsuarios: false,
			totalesNext: [],
			inscripciones: [],
			inscripcionesNexEvento: [],
			totalesRegistros: [],
			totalInsNoMiembros: 0,
			userKey: 0,
			inscripcionKey: 0,



		}),
		mounted() {
			this.getInscripciones()
			this.getRegistros()

			this.getNextEventos()

		},
		methods: {
			async getNextEventos() {
				try {
					let me=this
					const {data} = await axios.get('evento/query-next-list', configuration)
					this.nextEventos = await data
					this.inscripcionesNexEvento = []
					for (let index = 0; index < me.nextEventos.length; index++) {
						const eventoId = this.nextEventos[index]._id
						const resp = await axios.get(`inscripcion/list-by-event-id?evento=${eventoId}`,  configuration)
						var totalMiembros=0
						var totalNoMiembros=0
						var otrasEspecialidades=0
						var estudiantes=0
						var industria=0
						var total=0
						if (resp.data && resp.data.length > 0) {
							me.inscripciones = resp.data.map(function(item){
								if (item.user?.categoria != null && item.user.categoria == 'Dermatologo Miembro'){
									totalMiembros++
								}
								if (item.user?.categoria != null && item.user.categoria == 'Dermatologo No-Miembro'){
									totalNoMiembros++
								}
								if (item.user?.categoria != null && item.user.categoria == 'Otras Especialidades'){
									otrasEspecialidades++
								}
								if (item.user?.categoria != null && item.user.categoria == 'Estudiante'){
									estudiantes++
								}
								if (item.user?.categoria != null && item.user.categoria == 'Industria'){
									industria++
								}
								total = totalMiembros+totalNoMiembros+otrasEspecialidades+estudiantes+industria
								return item.user
							}).filter(function (el) {
								return el != null;
							});
						}
						me.inscripcionesNexEvento.push({
							titulo: me.nextEventos[index].titulo,
							totalMiembros: totalMiembros,
							totalNoMiembros: totalNoMiembros,
							otrasEspecialidades: otrasEspecialidades,
							estudiantes: estudiantes,
							industria: industria,
							total: total
						})
					}
				} catch (error) {
					console.log(error)
				}
			},

			async getRegistros(){
				this.loadingUsuarios = true
				try {
					const response = await axios.get('user/list', configuration)
					this.users = response.data
				} catch (e) {
					console.log(e.message)
				} finally {
					this.loadingUsuarios = false
					this.userKey += 1;
				}
			},
			async getInscripciones(){
				try {
					const response = await axios.get('evento/query-next', configuration)

					if (response.data.data){
						this.nextEvento = response.data.data;
						const resp = await axios.get(`inscripcion/list-by-event-id?evento=${this.nextEvento._id}`,  configuration)
						this.inscripciones = resp.data.map(function(item){
								return item.user
						}).filter(function (el) {
							return el != null;
						});
					}


				} catch (e) {
					console.log(e.message)
				} finally {
					this.loadingInscripciones = false
					this.inscripcionKey += 1;
				}
			},
		},
		computed: {
			existeNext(){
				return this.nextEvento != ''
			}
		}

	}
</script>

<style lang="sass">

</style>